.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #8C52FF;
  max-height: 20vh;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  color: white;
}

.App-link {
  color: #61dafb;
}